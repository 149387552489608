.App {
  text-align: center;
  background-color: #282c34;
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Arial', sans-serif;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #61dafb;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #21a1f1;
}

.share-buttons {
  margin-top: 20px;
}

.share-buttons a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
  padding: 10px 20px;
  border: 1px solid white;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.share-buttons a:hover {
  background-color: #555;
}

p {
  font-size: 1.5em;
}

.App-footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}
